body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

/* CSS File */
/* For screens smaller than or equal to 768px (mobile devices) */
@media (max-width: 768px) {
  /* Add hamburger menu styles */
  /* You can hide or show the hamburger menu icon and handle its functionality */
}
